import {kyRequest, BACKEND_HOST, responseCallback, prepareResponse} from "api/api";
import { format } from "date-fns";
import { downloadBlob } from "helper/blob/blob";
import {TYPE_USER_PROFILE} from "../../../profile-route/redux-store/profile/profile";

export type User = TYPE_USER_PROFILE;

/**
 * @route api/user
 * @post
 */
export const apiUser = async (args: { page: number; filter?: any; exportXLS?: boolean}) => {
    const {page, filter, exportXLS} = args;
    let params = { ...filter };
    const urlSearchParams = new URLSearchParams(params);
    if (exportXLS) {
        urlSearchParams.append("export", "1");
    }
    if (filter) {
        const keys = Object.keys(filter);
        keys.forEach(key => {
            let val = filter[key];
            switch (key) {
                case "per_page": {
                    urlSearchParams.delete(key);
                    val && urlSearchParams.set(key, val);
                    break;
                }
                case "limit":
                    urlSearchParams.delete(key);
                    val = exportXLS ? val : null;
                    val && urlSearchParams.set(key, val);
                    break;
                case "with_request":
                case "is_draft":
                case "is_vip": {
                    urlSearchParams.delete(key);
                    val !== null && urlSearchParams.set(key, val ? "1" : "0");
                    break;
                }
                case "covid":
                    urlSearchParams.set(key, "1");
                    break;
                case "forum_themes":
                    urlSearchParams.delete(key);
                    [...val].forEach((v, i) => urlSearchParams.append(key + "[" + i + "]", "" + v));
                    break;
                case "covid_state":
                case "state":
                case "sector": {
                    urlSearchParams.delete(key);
                    val = Number.isInteger(Number.parseInt(val)) ? val : -1;
                    val > -1 && urlSearchParams.set(key, val);
                    break;
                }
                case "min_date":
                case "max_date": {
                    urlSearchParams.delete(key);
                    val && urlSearchParams.set(key, format(new Date(val), "yyyy-MM-dd"));
                    break;
                }
                default: {
                    !val && urlSearchParams.delete(key);
                }
            }
        });
    }
    const response = await kyRequest.get(`${BACKEND_HOST}/user?page=${page}&${urlSearchParams.toString()}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        timeout: exportXLS ? 300000 : 30000
    });
    if (exportXLS) {
        response.blob().then(blob => downloadBlob(blob, "kdw_users.xls"));
    }
    return responseCallback(response, exportXLS, false);
};


export const apiUserStat = async(role:any) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/user/stat?role=${role}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        timeout: 30000
    });
    return responseCallback(response);
};


export const apiUserDocState = async(args:any) => {

    const response = await kyRequest.post(`${BACKEND_HOST}/user/doc`, {
        json:args,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return prepareResponse(response)
}