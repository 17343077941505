import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "redux-store/index";
import {deleteChat, getChatMessages, getChats, saveChat} from "./api";
import {LangEnum} from "../admin-expo/enums";
import {makeBaseReduxAction, makeClearCondition, makeConditionReducers} from "helper/redux";

export type SupportChat = {
    id: number;
    tg_id: string,
    tg_username: string,
};
export const slicerChats = createSlice({
    name: "chats",
    initialState: {
        items: [] as Array<SupportChat>,
        messages: {} as any,
        current: null as SupportChat,
        condition: makeClearCondition(),
    },
    reducers: {
        ...makeConditionReducers(),
        onItemMessages: (state, action) => {
            const list = action.payload || [];
            if (!list.length) return;
            const chat = list[0].chat_id;
            state.messages = {
                ...state.messages,
                [chat]: [...((state.messages?.[chat]) as any || []), ...list ]
            }
        },
        setItems: (state, action) => {
            const items = action.payload as any[];
            state.items = [...items];
        },
    },
});

export const {
    onRequest,
    onFail,
    onNoticeHide,
    onItemMessages,
    setItems,
    stopLoading
} = slicerChats.actions;

const baseStateAsync = makeBaseReduxAction((dispatch: any) => {
    dispatch(onNoticeHide());
    dispatch(onRequest());
}, (dispatch, r) => {
    if (r) {
        dispatch(onFail(r));
    }
}, (dispatch) => {
    dispatch(stopLoading());
});

export const asyncGetChats = (params?: any, callback?: (result: any) => void) => {
    return baseStateAsync(getChats, params, callback, (dispatch: any, result: any) => {
        dispatch(setItems(result.data));
    });
};
export const asyncGetChatMessages = (params?: any, callback?: (result: any) => void) => {
    return baseStateAsync(getChatMessages, params, callback, (dispatch: any, result: any) => {
        dispatch(onItemMessages(result.data));
    });
};
export const asyncSaveChat = (post?: any, lang?: LangEnum, callback?: (result: any) => void) => {
    return baseStateAsync(saveChat, {...post, lang}, callback, (dispatch: any, result: any) => {
        // dispatch(onItem(result.data));
    });
};
export const asyncDeleteChat = (post?: any, callback?: (result: any) => void) => {
    return baseStateAsync(deleteChat, post, callback, (dispatch: any, result: any) => {
        dispatch(stopLoading());
    });
};
export const reselectChats = (state: RootState) => {
    return state.chats.items;
};
export const reselectCurrentChat = (state: RootState) => {
    return state.chats.current;
};
export const reselectChatsCondition = (state: RootState) => {
    return state.chats.condition;
};
export default slicerChats.reducer;
