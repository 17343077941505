import {kyRequest, BACKEND_HOST, responseCallback, kyRequestBase} from "api/api";
import {format} from "date-fns";
import {getFileFromUrl} from "../profile-route-post/api/profile-post/profile-post";

/**
 * @route api/support/chat/
 * @get
 */
export const getChats = async (params?: any) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/support/chat/?` + new URLSearchParams(params).toString(), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response);
};

/**
 * @route api/support/chat/
 * @delete
 */
export const deleteChat = async (item: any) => {
    const response = await kyRequest.delete(`${BACKEND_HOST}/support/chat/${item?.id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};
/**
 * @route api/support/chat/id
 * @get
 */
export const getChatMessages = async (data: any) => {
    const response = await kyRequest.get(`${BACKEND_HOST}/support/chat/${data.chat_id}/message?`
            + new URLSearchParams({...data}).toString(), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    return responseCallback(response, false, true);
};
/**
 * @route api/support/chat/id
 * @get
 */
export const sendChatMessage = async (data: any) => {
    const response = await kyRequest.post(`${BACKEND_HOST}/support/chat/${data.chat_id}/message`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        json: data,
    });
    return responseCallback(response, false, true);
};
export const saveChat = async (item: any, lang: string) => {
    const data = new FormData();
    Object.keys(item).forEach(key => {
        if (["date", "photo"].indexOf(key) == -1) data.append(key, item[key]);
    });
    if (item.photo) {
        data.append("photo", await getFileFromUrl(item.photo, item.photo_name));
    }
    if (item.date) {
        data.append("date", format(item.date, "yyyy-MM-dd HH:mm:ss"));
    }
    if (item.id > 0) data.append("_method", "PUT");
    const response = await responseCallback(
        await kyRequestBase.post(`${BACKEND_HOST}/support/chat/${item.id || ""}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "Accept-Language": lang || item.lang,
            },
            body: data,
        }),
        false,
        true,
    );

    return response;
};
