import React, {useEffect, useState} from "react";
import __translate from "helper/locale/locale";
import KdwNavLink from "./KdwNavLink";
import {useDispatch, useSelector} from "react-redux";
import {reselect_Profile_entity,} from "../../../controllers/profile-route/redux-store/profile/profile";
import {MANAGER_ROLES, RegistrationRole} from "../../../constants/constants";
import {Edit} from "@material-ui/icons";
import {
    asyncGetVisit,
    onMenuItem,
    reselectVisitsMenuItems
} from "../../../controllers/visits/redux-store/visits/visits";

const ModeratorLinks = ({ classes, to } : any) => {
    const user = useSelector(reselect_Profile_entity);
    const [open, setOpen] = useState(window.location.pathname.split('/')?.[1] === 'moderation');
    const dispatch = useDispatch();
    const items = useSelector(reselectVisitsMenuItems);
    const  getVisitName = (n:any) => {
        if (!items?.[n]) {
            return __translate("profile.moderation_visit_users", "Участники");
        }
        return items[n];
    }
    useEffect(() => {
        user?.options?.visits?.forEach((v:any) => {
            dispatch(asyncGetVisit(v, (r:any) => {
                dispatch(onMenuItem(r.data || {}))
            }));
        })
    }, [user?.options?.visits?.length || 0]);
    if ("adminmanager".indexOf(user?.user_type) == -1) return <></>;
    const isA = user?.user_type == "admin";
    const reportManager = user.role == MANAGER_ROLES.REPORTS_EDITOR || user.role == MANAGER_ROLES.ALL_REPORTS_MANAGER;

    return (
        <React.Fragment>
            <KdwNavLink
                includeToActive={"randomstring"}
                onClick={() => setOpen(!open)}
                icon={(color: any, className: any) => (
                    <Edit color={color} className={className} />
                )}
                text={__translate("profile.moderation_title", "Модерация")}
            />
                {!open ? null : (
                    <>
                        {(isA) && (<KdwNavLink
                            includeToActive="/ru/admin"
                            onClick={() => to('/ru/admin')}
                            icon={(color: any, className: any) => (<></>)}
                            text={__translate("profile.admin_page", "Админка")}
                        />)}
                        {(isA || (user.role & MANAGER_ROLES.ARTICLE_MANAGER)>0) && (<KdwNavLink
                            includeToActive="/moderation/articles"
                            onClick={() => to('/moderation/articles')}
                            icon={(color: any, className: any) => (<></>)}
                            text={__translate("profile.moderation_articles", "Статьи")}
                        />)}
                        {(isA || (user.role & MANAGER_ROLES.STARTUP_EDITOR)>0) && (<KdwNavLink
                            includeToActive="/moderation/startups"
                            onClick={() => to('/moderation/startups')}
                            icon={(color: any, className: any) => (<></>)}
                            text={__translate("profile.moderation_startups", "Стартапы")}
                        />)}
                        {(isA || (user.role & MANAGER_ROLES.EXPO_EDITOR)>0) && (<KdwNavLink
                            includeToActive="/moderation/expo"
                            onClick={() => to('/moderation/expo/1')}
                            icon={(color: any, className: any) => (<></>)}
                            text={__translate("profile.moderation_expo", "Выставка")}
                        />)}
                        {((user.role & MANAGER_ROLES.EXPO_EDITOR)>0) && (<KdwNavLink
                            includeToActive="/moderation/companies"
                            onClick={() => to('/moderation/companies')}
                            icon={(color: any, className: any) => (<></>)}
                            text={__translate("profile.moderation_companies", "Компании")}
                        />)}
                        {(isA || reportManager) && (
                            <>
                                <KdwNavLink
                                    includeToActive="/moderation/reports"
                                    onClick={() => to('/moderation/reports')}
                                    icon={(color: any, className: any) => (<></>)}
                                    text={__translate("profile.moderation_reports", "Доклады")}
                                />
                            </>)}
                        {(reportManager) && (
                            <>
                                <KdwNavLink
                                    includeToActive="/moderation/participants/1?"
                                    onClick={() => to('/moderation/participants/1')}
                                    icon={(color: any, className: any) => (<></>)}
                                    text={__translate("profile.moderation_participants", "Участники")}
                                />
                            </>)}
                        {(isA || (user.role & MANAGER_ROLES.FORUM_THEME_EDITOR)>0
                            ||  reportManager
                        ) && (<KdwNavLink
                            includeToActive="/moderation/program"
                            onClick={() => to('/moderation/program')}
                            icon={(color: any, className: any) => (<></>)}
                            text={__translate("profile.moderation_program", "Программа")}
                        />)}
                        {(user.role & MANAGER_ROLES.VISIT_USERS_MANAGER)>0 && user?.options?.visit_id &&  (<KdwNavLink
                            includeToActive="/admin/visits/"
                            onClick={() => to('/ru/admin/visits/'+ user?.options?.visit_id +'/users')}
                            icon={(color: any, className: any) => (<></>)}
                            text={getVisitName(user?.options?.visit_id)}
                        />)}
                        {(user.role & MANAGER_ROLES.VISIT_USERS_MANAGER)>0 && user?.options?.visits?.map((v:any, n:number) => (<KdwNavLink
                            includeToActive="/admin/visits/"
                            onClick={() => to('/ru/admin/visits/'+ v +'/users')}
                            icon={(color: any, className: any) => (<></>)}
                            text={getVisitName(v)}
                        />))}
                        {(user.role & MANAGER_ROLES.USER_ROLE_MANAGER)>0 && (
                            <>
                                {(user.options?.edit_roles || []).map((r) =>  {
                                    if (Number(r) == RegistrationRole.PARTICIPANT_PREMIUM) return '';
                                    return (<KdwNavLink
                                        includeToActive={"/moderation/participants/1/"+r}
                                        onClick={() => to("/moderation/participants/1/" + r)}
                                        icon={(color: any, className: any) => (<></>)}
                                        text={__translate("profile.moderation_participants_"+r, "Участники"+r)}
                                    />)
                                })}
                                {(user.options?.edit_roles?.findIndex((v) => Number(v) === RegistrationRole.PARTICIPANT_PREMIUM)!==-1
                                        || user.options?.manage_vips)
                                    && (<KdwNavLink
                                        includeToActive={"/moderation/participants/1/4"}
                                        onClick={() => to("/moderation/participants/1/4")}
                                        icon={(color: any, className: any) => (<></>)}
                                        text={__translate("profile.moderation_participants_4", "Участники")}
                                    />)
                                }
                            </>)
                        }
                        {(user.role & MANAGER_ROLES.SUPPORT)>0 && (
                            <KdwNavLink
                                        includeToActive={"/moderation/support"}
                                        onClick={() => to("/moderation/support")}
                                        icon={(color: any, className: any) => (<></>)}
                                        text={__translate("profile.support_chats", "Поддержка")}/>
                        ) || ''}
                    </>
                )}
        </React.Fragment>
    );
};
export default ModeratorLinks;
